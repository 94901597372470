export const COOKIE_DURATION_MIDNIGHT = 'midnight';

export const getCookies = (cookieNames = []) => {
  let cookies = {};

  document.cookie.split(';').forEach((value) => {
    const cookieAsArray = value.split('=');
    const cookieKey = cookieAsArray[0].trim();
    const cookieValue = cookieAsArray[1] || '';

    const addCookie = cookieNames.length === 0 ? true : cookieNames.includes(cookieKey);

    if (cookieKey.length > 0 && cookieValue.length > 0 && addCookie) {
      cookies = { ...cookies, [cookieKey]: cookieValue.trim() };
    }
  });

  return cookies;
};

/**
 * @param {number|string} days
 */
export const getCookieExpirationDate = (days) => {
  const expirationDate = new Date();

  if (days === 'midnight') {
    expirationDate.setHours(23, 59, 59, 0);
  } else {
    expirationDate.setTime(expirationDate.getTime() + (days * 24 * 60 * 60 * 1000));
  }

  return expirationDate.toUTCString();
};

/**
 * @param {string} cookieName
 * @param {string} cookieValue
 * @param {number} cookieExpirationDays
 */
export const createCookie = (cookieName, cookieValue, cookieExpirationDays) => {
  document.cookie = `${cookieName}=${cookieValue}; expires=${getCookieExpirationDate(cookieExpirationDays)}; path=/; secure`;
};

/**
 * @param {string} cookieName
 */
export const removeCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
