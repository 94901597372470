import { getContentType } from '../../utils/pageType';

const ROUTES_SUPPORTED = ['video', 'quiz', 'article', 'game'];

const _addVideoData = () => {
  const { factoryid } = document.querySelector('[data-factoryid]').dataset;
  const { program } = document.querySelector('#programTitle').dataset;

  return {
    content_status: 'replay',
    video_factory_id: factoryid,
    program,
  };
};

export const content = () => {
  const context = window.LUMNI_CONTEXT;

  if (!ROUTES_SUPPORTED.includes(context.route)) return {};

  let data = {
    content_type: getContentType(context),
    content_title: context.item.slug,
  };

  if (context.route === 'video') {
    data = { ...data, ..._addVideoData() };
  }

  return data;
};
