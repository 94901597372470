import Piano from './Piano/Piano';

class CustomDataLayer {
  /**
   * @param {object} defaultDataLayer
   */
  constructor(defaultDataLayer = {}) {
    this.defaultDataLayer = defaultDataLayer;
  }

  getDataLayer() {
    return (new Piano(this.defaultDataLayer)).getDataLayer();
  }
}

export default CustomDataLayer;
