import { getCookies } from '../../../../../utils/cookies';

const ROUTES_SUPPORTED = ['search'];
const THEMATIC = 'matiere';

/**
 * @param {string} originPage
 * @param {string} originPageType
 * @return {{}|{ise_page: string, subject: string, etablissement: string, niveau: string}}
 */
export const search = (originPage, originPageType) => {
  if (!ROUTES_SUPPORTED.includes(window.LUMNI_CONTEXT.route)) return {};

  const parameters = new URLSearchParams(window.location.search);
  const { schoolLevel = '', establishment = '' } = getCookies();
  const subject = THEMATIC === originPageType ? originPage : '';

  return {
    ise_page: parameters.get('page') || '1',
    etablissement: establishment,
    niveau: schoolLevel,
    subject,
  };
};
