const FEATURE = 'interet_compte';
const ACTIONS_WITH_ZONE = ['login', 'registration'];

/**
 * @param {object} data
 * @return {{}|{feature: string, click: string}}
 */
export const popinAccount = (data) => {
  const hitData = { feature: FEATURE };

  if (ACTIONS_WITH_ZONE) {
    localStorage.setItem('Tracking:zone', `popin_${FEATURE}`);
  }

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'je_me_connecte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'je_cree_un_compte',
      };
    default:
      return {};
  }
};
