const FEATURE = 'incitation_compte';
const ACTIONS_WITH_ZONE = ['login', 'registration'];

const INCITATION_TYPES = {
  favoris: 'favoris',
  historic: 'mon_historique',
  popinFreemium: 'favoris',
};

/**
 * @param {object} data
 * @return {{}|{feature: string, incitation_type: string, click: string}}
 */
export const popinIncentive = (data) => {
  const hitData = {
    feature: FEATURE,
    incitation_type: INCITATION_TYPES[data.type],
  };

  if (ACTIONS_WITH_ZONE) {
    localStorage.setItem('Tracking:zone', `popin_${FEATURE}`);
  }

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'j_ai_deja_un_compte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'creer_ton_compte',
      };
    default:
      return {};
  }
};
