import { getCookies } from '../../../../../utils/cookies';
import { CONTENT_TYPE } from '../../utils/pageType';
/**
 * @return {{
 * click: string, zone: string, ise_page: number, position: number, ise_keyword: string,
 *  content_type: string, subject: string
 * }}
 */
export const searchV2 = (data) => {
  const { thematic } = getCookies();

  return {
    click: data.click,
    zone: 'resultat_de_recherche',
    ise_page: parseInt(data.ise_page, 10),
    position: data.position.toString(),
    ise_keyword: data.ise_keyword,
    content_type: CONTENT_TYPE[data.content_type],
    subject: thematic || '',
  };
};
