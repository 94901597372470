const FEATURE = 'newsletter';

/**
 * @param {object} data
 * @return {{feature: string, zone, click}}
 */
export const newsletter = (data) => {
  const { zone, click } = data;

  return {
    feature: FEATURE,
    click,
    zone,
  };
};
