import { getCookies } from '../../../utils/cookies';

export const subDomain = window.location.host.split('.')[0] || null;

const USER_IS_ABROAD_COOKIE_NAME = 'lumni_is_user_abroad';

export const SESSION_NAMES = {
  userId: 'lumni_student_user_id',
  email: 'lumni_student_user_email',
  firstName: 'lumni_student_user_first_name',
  schoolLevel: 'lumni_student_user_school_level',
  points: 'lumni_student_user_points',
  level: 'lumni_student_user_level',
  avatarId: 'lumni_student_user_avatar_id',
};

/**
 * @return {boolean}
 */
export const isConnected = () => document.cookie.indexOf(SESSION_NAMES.userId) !== -1;

/**
 * @return {string|null}
 */
export const getId = () => sessionStorage.getItem(SESSION_NAMES.userId);

/**
 * @return {string|null}
 */
export const getEmail = () => sessionStorage.getItem(SESSION_NAMES.email);

/**
 * @return {string|null}
 */
export const getFirstName = () => sessionStorage.getItem(SESSION_NAMES.firstName);

/**
 * @return {string|null}
 */
export const getSchoolLevel = () => sessionStorage.getItem(SESSION_NAMES.schoolLevel);

/**
 * @return {string|null}
 */
export const getPoints = () => sessionStorage.getItem(SESSION_NAMES.points);

/**
 * @return {string|null}
 */
export const getLevel = () => sessionStorage.getItem(SESSION_NAMES.level);

/**
 * @return {string|null}
 */
export const getAvatarId = () => sessionStorage.getItem(SESSION_NAMES.avatarId);

/**
 * @return {boolean}
 */
export const hasId = () => getId() && getId() !== 'undefined';

/**
 * @return {boolean}
 */
export const hasEmail = () => getEmail() && getEmail() !== 'undefined';

/**
 * @return {boolean}
 */
export const hasFirstName = () => getFirstName() && getFirstName() !== 'undefined';

/**
 * @return {boolean}
 */
export const hasSchoolLevel = () => getSchoolLevel() && getSchoolLevel() !== 'undefined';

/**
 * @return {boolean}
 */
export const hasPoints = () => getPoints() && getPoints() !== 'undefined';

/**
 * @return {boolean}
 */
export const hasInformationInSession = () => hasId() && hasEmail() && hasFirstName();

/**
 * @returns {boolean}
 */
export const isUserAbroad = () => {
  const cookieIsUserAbroad = getCookies([USER_IS_ABROAD_COOKIE_NAME])[USER_IS_ABROAD_COOKIE_NAME] || 'false';

  return cookieIsUserAbroad === 'true';
};

/**
 * @return {boolean}
 */
export const hasAvatarId = () => getAvatarId() && getAvatarId() !== 'undefined';
