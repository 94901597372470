const BLOCS_BY_ROUTE = {
  school: 'mea_hp_niveau',
  'middle-school': 'mea_hp_niveau',
  'high-school': 'mea_hp_niveau',
  establishment: 'mea_hp_etablissement',
  homepage: 'mea_hpp',
};

/**
 * @param {object} data
 * @return {{feature: string, zone: string, new: boolean, position, click}|{}}
 */
export const highlight = (data) => {
  const { route } = window.LUMNI_CONTEXT;

  if (!Object.keys(BLOCS_BY_ROUTE).includes(route)) {
    return {};
  }

  return {
    zone: 'mea',
    feature: `bloc_${BLOCS_BY_ROUTE[route]}`,
    position: data.position.toString(),
    click: data.slug,
    new: data.isNew,
  };
};
