const MAPPING_TABS = {
  'success-data': 'mes_succes',
  'personal-data': 'donnees_personnelles',
  password: 'mot_de_passe',
};

/**
 * @param {object} data
 */
export const myInfos = (data) => ({
  tab: MAPPING_TABS[data.tab] || '',
});
