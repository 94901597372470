import { getPageTypeValue, getPreviousPageType } from '../../utils/pageType';

export const HOMEPAGE = 'accueil';
export const GAMIFICATION_LANDING = 'autre';
export const INCITATION_PROFILE_LANDING = 'incitation_profile';

/**
 * @param {string} value
 * @return {string}
 * @private
 */
export const _getLastSegment = (value) => decodeURIComponent(value.split('/').pop());

const _getZone = () => {
  const zone = localStorage.getItem('Tracking:zone') || '';
  localStorage.removeItem('Tracking:zone');

  return zone;
};

export const base = () => {
  const referrer = document.referrer ? new URL(document.referrer).pathname : '';
  const pageType = getPageTypeValue(window.LUMNI_CONTEXT);
  const previousPageType = getPreviousPageType();
  let type;

  let page;
  switch (pageType) {
    case HOMEPAGE:
      page = HOMEPAGE;
      break;
    case GAMIFICATION_LANDING:
      page = 'explication_gamification';
      break;
    case INCITATION_PROFILE_LANDING:
      page = 'pourquoi_avoir_un_compte';
      type = 'autre';
      break;
    default:
      page = _getLastSegment(window.location.pathname);
      break;
  }

  return {
    page,
    page_type: type ?? pageType,
    origin_page: previousPageType === HOMEPAGE ? HOMEPAGE : _getLastSegment(referrer),
    origin_page_type: previousPageType,
    zone: _getZone(),
  };
};
