const FEATURE = 'incitation_compte_bubble';

/**
 * @param {object} data
 * @return {{}|{feature: string, click: string}}
 */
export const bubbleIncentive = (data) => {
  const hitData = { feature: FEATURE };

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'je_me_connecte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'je_cree_un_compte',
      };
    default:
      return {};
  }
};
