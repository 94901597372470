const MAPPING_TABS = {
  revise: 'pour_reviser',
  test_your_knowledge: 'pour_tester_tes_connaissances',
  learn_while_having_fun: 'pour_apprendre_en_t_amusant',
};

/**
 * @param {object} data
 */
export const chapter = (data) => ({
  tab: MAPPING_TABS[data.tab] || '',
});
