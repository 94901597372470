/**
 * @param {object} data
 * @return {string}
 * @private
 */
const _getPage = (data) => {
  switch (data.action) {
    case 'quizStep':
      return `quiz_etape_${data.question}_${data.nbQuestions}`;
    case 'quizFinished':
      return 'quiz_resultat';
    default:
      return '';
  }
};

/**
 * @param {object} data
 */
export const quiz = (data) => ({
  page: _getPage(data),
  result_quiz: data.resultLabel || '',
});
