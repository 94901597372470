const MAPPING_TABS = {
  resume: 'reprendre',
  replay: 'revoir',
};

/**
 * @param {object} data
 */
export const myHistoric = (data) => ({
  tab: MAPPING_TABS[data.tab] || '',
});
