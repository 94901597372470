const INCITATION_TYPES = {
  historic: 'mon_historique',
  gamification: 'gamification',
};

/**
 * @param {object} data
 * @return {{feature: string, incitation_type: string}}
 */
export const popinIncentive = (data) => ({
  feature: 'incitation_compte',
  incitation_type: INCITATION_TYPES[data.type],
});
