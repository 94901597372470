/**
 * @param {object} data
 * @return {object}
 */
export const removeEmptyValue = (data) => {
  if (Object.keys(data).length === 0) return null;

  return Object.fromEntries(
    Object.entries(data).filter(
      ([, val]) => (typeof val === 'string' && val.trim() !== '')
          || typeof val !== 'string',
    ),
  );
};

/**
 * @param {object|null} data
 * @return {object}
 */
export const formatStringValue = (data) => {
  if (data === null) return {};

  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'string') {
      data[key] = data[key]
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[,:]/g, '')
        .replace(/-| /g, '_')
        .replace(/_+/i, '_')
        .toLowerCase();
    }
  });

  return data;
};
