/**
 * @param {string} schoolLevel
 * @param {string} thematic
 * @return {[]}
 */
export const getChapter = (schoolLevel, thematic) => {
  const { qualification } = window.LUMNI_CONTEXT;

  // If no chapters
  // eslint-disable-next-line no-prototype-builtins
  if (!qualification.hasOwnProperty('chapters')) return [];

  let { chapters } = qualification;

  chapters = chapters.filter(
    (chapter) => chapter.schoolLevel === schoolLevel && chapter.thematic === thematic,
  );

  return chapters[0] || [];
};
