/**
 * @param {object} data
 * @return {{}|{feature: string, click: string}}
 */
export const incentiveAccount = (data) => {
  const hitData = {
    feature: 'incitation_compte',
    incitation_type: data.type,
  };

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'j_ai_deja_un_compte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'creer_ton_compte',
      };
    default:
      return {};
  }
};
