/**
 * @type {RegExp}
 */
export const splitRegexURL = new RegExp([
  '^(https?:)//', // protocol
  '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
  '(/{0,1}[^?#]*)', // pathname
  '(\\?[^#]*|)', // search
  '(#.*|)$', // hash
].join(''));

export const varUrl = (arg) => ((typeof arg !== 'undefined') ? arg : '');

/**
 * @param {String} location
 */
export const getArgumentsUrl = (location) => {
  const match = location.href.match(splitRegexURL);
  return match[5].split('/').filter(Boolean);
};

/**
 * Return information about the current page
 * @param {Array} argumentsUrl
 * @param {string} context Can be the value of the establishment
 */
export const getPageInformations = (context, argumentsUrl = []) => {
  const isHomepage = typeof argumentsUrl[0] === 'undefined';
  const isSearchPage = argumentsUrl[0] === 'recherche';
  const isGlobalArchivePage = argumentsUrl.includes('tous-les-jeux')
    || argumentsUrl.includes('toutes-les-series')
    || argumentsUrl.includes('tous-les-dossiers')
    || argumentsUrl.includes('tous-les-articles')
    || argumentsUrl.includes('tous-les-quiz')
    || argumentsUrl.includes('tous-les-cours-lumni')
    || argumentsUrl.includes('toutes-les-videos');

  const isESTArchivePage = argumentsUrl.includes('tous-les-contenus'); // EST : Establishment / SchoolLevel / Thematic
  const isUserPage = argumentsUrl.includes('mes-contenus') || argumentsUrl.includes('mes-informations');
  const isUserSecurityPage = argumentsUrl.includes('inscription')
    || argumentsUrl.includes('connexion')
    || argumentsUrl.includes('mot-de-passe-oublie')
    || argumentsUrl.includes('reinitialiser-le-mot-de-passe');

  const isArchivePage = isGlobalArchivePage || isESTArchivePage;
  const isContentPage = !isHomepage && !isUserPage
    && !isSearchPage
    && !isArchivePage
    && !isUserSecurityPage
    && context === null;

  return {
    isSearchPage,
    isHomepage,
    isESTArchivePage,
    isGlobalArchivePage,
    isUserPage,
    isArchivePage,
    isContentPage,
    isUserSecurityPage,
  };
};
