/* eslint-disable prefer-destructuring */

import {
  varUrl,
  getArgumentsUrl,
  getPageInformations,
  splitRegexURL,
} from './url';

const establishments = ['primaire', 'college', 'lycee'];
const containerTypes = ['chapter', 'folder', 'serie', 'program', 'brand'];
const contentTypes = ['article', 'jeu', 'video', 'quiz'];

/**
 * @param {String} location
 */
const getQueryParamUrl = (location) => {
  const query = new URLSearchParams(location.search);

  return query.get('query');
};

/**
 * @param {String} location
 */
const getEstablishmentParamUrl = (location) => {
  const query = new URLSearchParams(location.search);

  return query.get('establishment');
};

export const getReferrerUrl = () => {
  const isReferrer = document.referrer !== '';
  const referrerContent = isReferrer
    ? document.referrer.match(splitRegexURL)[5].split('/').filter(Boolean)
    : null;

  return {
    isReferrer,
    referrerContent,
  };
};

/**
 * Return object of the qualifications of the page
 * @param {string|null} establishment
 * @param {Array} argumentsUrl
 * @param {Object} pageInformations
 */
const getUrlInformations = (establishment, argumentsUrl, pageInformations) => {
  const schoolLevel = establishment !== null && varUrl(argumentsUrl[1]) !== 'segment' ? varUrl(argumentsUrl[1]) : null;
  let thematic = schoolLevel !== null ? varUrl(argumentsUrl[2]) : null;
  thematic = pageInformations.isArchivePage ? null : thematic;

  return {
    establishment,
    schoolLevel,
    thematic,
  };
};

/**
 * Set info about the parent of the current content (i.e the last chapter/program/serie/brand)
 * @param {Array} argumentsUrl
 */
const containerInformations = (argumentsUrl) => {
  const containerParameters = {
    containerSlug: null,
    containerTitle: null,
    containerType: null,
  };

  containerTypes.forEach((type) => {
    const node = document.querySelector(`.breadcrumb--type-${type}`);

    if (node !== null) {
      containerParameters.containerSlug = type === 'chapter' ? varUrl(argumentsUrl[3]) : varUrl(argumentsUrl[1]);
      containerParameters.containerTitle = node.textContent.trim();
      containerParameters.containerType = type;

      return false;
    }

    return true;
  });

  return containerParameters;
};

/**
 * Set info about the current content (i.e the game / article / video )
 * @param {Array} argumentsUrl
 */
const contentInformations = (argumentsUrl) => {
  const contentParameters = {
    contentType: null,
  };
  if (contentTypes.includes(argumentsUrl[0])) contentParameters.contentType = argumentsUrl[0];

  return contentParameters;
};

export const splitUrls = (location) => {
  if (location === null) {
    return {};
  }

  const argumentsUrl = getArgumentsUrl(location);
  const queryParam = getQueryParamUrl(location);
  const referrerUrl = getReferrerUrl(location);
  const establishmentParam = getEstablishmentParamUrl(location);

  const establishment = establishments.includes(argumentsUrl[0]) ? argumentsUrl[0] : null;
  const pageInformations = getPageInformations(establishment, argumentsUrl);
  const urlInformation = getUrlInformations(establishment, argumentsUrl, pageInformations);

  return {
    ...containerInformations(argumentsUrl),
    ...contentInformations(argumentsUrl),
    ...urlInformation,
    ...pageInformations,
    referrerUrl,
    argumentsUrl,
    queryParam,
    establishmentParam,
  };
};
