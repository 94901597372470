import { security } from './events/login/security';

const LOGIN_EVENT = 'Tracking:login';

class PianoLogin {
  /**
   * @param {PianoEventManager} eventManager
   */
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.events = {
      security,
    };
  }

  init() {
    document.addEventListener(LOGIN_EVENT, this._onLogin.bind(this), false);
  }

  /**
   * @param {string} type
   * @param {object} data
   * @private
   */
  _getData(type, data) {
    return type in this.events ? this.events[type](data) : null;
  }

  /**
   * @param {object} detail
   * @private
   */
  _onLogin({ detail }) {
    this.eventManager.send('login', this._getData(detail.type, detail.data));
  }
}

export default PianoLogin;
