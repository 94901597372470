const FEATURE = 'incitation_compte';

/**
 * @param {object} data
 * @return {{}|{feature: string, click: string}}
 */
export const incitationAccount = (data) => {
  const hitData = {
    feature: FEATURE,
    incitation_type: data.incitationType || 'explication_compte',
  };

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'je_me_connecte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'je_cree_un_compte',
      };
    case 'registration_end_quiz':
      return {
        ...hitData,
        type: 'progress_end_of_quiz',
        click: 'creer_ton_compte',
      };
    default:
      return {};
  }
};
