const CONTENT_TYPE = {
  quiz: 'le_quiz_à_la_une',
  game: 'le_jeu_à_la_une',
};

/**
 * @param {object} data
 * @return {{zone: string, feature: string, position: number, click: string, new: boolean}|{}}
 */
export const featuredContent = (data) => ({
  zone: 'slider',
  feature: `bloc_${CONTENT_TYPE[data.type]}`,
  position: 0,
  click: data.slug,
  new: data.isNew,
});
