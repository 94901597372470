const KEY_LOCAL_STORAGE = 'lumni_context';

export const MAPPING_ROUTE = {
  homepage: 'accueil',
  establishment: 'etablissement',
  school: 'niveau',
  'middle-school': 'niveau',
  'high-school': 'niveau',
  thematic: 'matiere',
  chapter: 'chapitre',
  all_contents: 'archives',
  archives_schoolLevel: 'archives',
  video: 'contenu',
  article: 'contenu',
  quiz: 'contenu',
  game: 'contenu',
  brand: 'programme',
  serie: 'programme',
  program: 'programme',
  folder: 'dossier',
  login: 'login',
  registration: 'login',
  forgot_password: 'login',
  'reset-password': 'login',
  my_profil: 'compte',
  my_contents: 'compte',
  my_historic: 'compte',
  my_infos: 'compte',
  search: 'recherche',
  sitemap: 'sitemap',
  quiz_list_exam: 'hub_examen',
  contact: 'contact',
  cgu: 'cgu',
  about: 'qui-sommes-nous',
  legal_notice: 'mentions-legales',
  gamification_landing: 'autre',
  incitation_profile: 'incitation_profile',
  my_lists: 'compte',
};

export const CONTENT_TYPE = {
  video: 'video',
  article: 'article',
  quiz: 'quiz',
  game: 'jeu',
  brand: 'programme',
  serie: 'programme',
  program: 'programme',
  folder: 'dossier',
};

/**
 * @param {object} context
 * @return {string}
 */
export const getPageTypeValue = (context) => MAPPING_ROUTE[context.route] || 'erreur';

/**
 * @param {object} context
 * @return {string}
 */
export const getContentType = (context) => CONTENT_TYPE[context.route] || 'erreur';

/**
 * @return {string|string}
 */
export const getPreviousPageType = () => {
  const previousContext = localStorage.getItem(KEY_LOCAL_STORAGE);

  // Update new context
  localStorage.setItem(KEY_LOCAL_STORAGE, JSON.stringify(window.LUMNI_CONTEXT));

  return (document.referrer && previousContext)
    ? getPageTypeValue(JSON.parse(previousContext))
    : '';
};
