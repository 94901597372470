import { formatStringValue, removeEmptyValue } from './utils/format';

class PianoEventManager {
  constructor() {
    this.queue = [];
    this.isLocked = false;
    this.delay = 0;
    this.lastHitAt = null;
  }

  /**
   * @param {string} type
   * @param {object|null} data
   */
  send(type, data) {
    if (data === null) return;

    // format data in snake_case
    data = removeEmptyValue(formatStringValue(data));

    // push the hit in a queue to send them sequentially
    this.queue.unshift({ type, data });

    // send hit if no other hit is being sent
    if (!this.isLocked) {
      this._sendHit();
    }
  }

  /**
   * @private
   */
  _sendHit() {
    // stop here if the TagCommander is not loaded yet
    if (!window.tC) return;

    // stop here if a hit is already being sent
    if (this.isLocked) return;

    // when a hit will be sent we lock this function
    // to prevent sending multiple hits at the same time
    this._lock();

    // we retrieve the last item of the queue
    const item = this.queue.pop();

    try {
      this._setDelay();

      setTimeout(() => {
        // call TagCommander
        if (typeof window.tC.event[item.type] === 'function') {
          window.tC.event[item.type](this, { event_name: item.type, ...item.data });
        }

        this.lastHitAt = Date.now();

        // unlock the function to allow new hit to be sent
        this._checkForNewHit();
      }, this.delay);
    } catch (e) {
      console.debug(`>>> ${item.type} not configured yet on TagCommander!`);
      this._checkForNewHit();
    }
  }

  _setDelay() {
    if (this.lastHitAt === null) {
      this.delay = 0;
    }

    this.delay = this.lastHitAt + 400 > Date.now() ? 400 : 0;
  }

  /**
   * @private
   */
  _checkForNewHit() {
    this._unlock();

    // check if other hits need to be sent, if so,
    // re call the function until the queue is empty
    if (this.queue.length > 0) {
      this._sendHit();
    }
  }

  /**
   * @private
   */
  _lock() {
    this.isLocked = true;
  }

  /**
   * @private
   */
  _unlock() {
    this.isLocked = false;
  }
}

export default PianoEventManager;
