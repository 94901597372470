const FEATURE = 'popin_incitation_login_first_visit';
const INCITATIONTYPE = 'first_visit';

/**
 * @param {object} data
 * @return {{}|{feature: string, incitation_type: string, click: string}}
 */
export const popinAdGamification = (data) => {
  const hitData = { feature: FEATURE, incitation_type: INCITATIONTYPE };

  switch (data.action) {
    case 'login':
      return {
        ...hitData,
        click: 'j_ai_deja_un_compte',
      };
    case 'registration':
      return {
        ...hitData,
        click: 'je_cree_un_compte',
      };
    default:
      return {};
  }
};
