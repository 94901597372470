const FEATURE = 'playlist';

/**
 * @param {object} data
 * @return {{}|{feature: string, content_type: string, content_title: string, click: string}}
 */
export const playlistPopin = (data) => {
  const hitData = { feature: FEATURE, content_type: data.type, content_title: data.title };

  switch (data.action) {
    case 'create':
      return {
        ...hitData,
        click: 'creer_une_liste',

      };
    case 'save':
      return {
        ...hitData,
        click: 'valider',
      };
    default:
      return {};
  }
};
