/**
 * @return {{feature: string, zone}}
 */
export const slider = (data) => {
  const { zone, feature } = data;
  return {
    zone,
    feature,
  };
};
