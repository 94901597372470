/**
 * @param {object} data
 * @return {{}|{chapter, click: string}|{theme_name, click: string}}
 */
export const chapter = (data) => {
  switch (data.action) {
    case 'theme':
      return {
        theme_name: data.name,
        click: 'theme',
      };
    case 'chapter':
      return {
        chapter: data.name,
        click: 'chapitre',
      };
    default:
      return {};
  }
};
