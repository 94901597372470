import DataLayer from './DataLayer';
import CustomDataLayer from './modules/tracking/CustomDataLayer';

try {
  const dataLayer = new DataLayer();
  dataLayer.init();

  const customDataLayer = new CustomDataLayer(dataLayer.getDataLayer());
  window.utag_data = customDataLayer.getDataLayer();
} catch (error) {
  console.log('Error', error);
}
