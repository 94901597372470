import { game } from './events/click/game';
import { chapter } from './events/click/chapter';
import { highlight } from './events/click/highlight';
import { newsletter } from './events/click/newsletter';
import { pdf } from './events/click/pdf';
import { quiz } from './events/click/quiz';
import { popinAccount } from './events/click/popin_account';
import { bubbleIncentive } from './events/click/bubble_incentive';
import { popinIncentive } from './events/click/popin_incentive';
import { incentiveAccount } from './events/click/incentive_account';
import { popinBackToSchool } from './events/click/popin_back_to_school';
import { userMenu } from './events/click/user_menu';
import { seeMoreContent } from './events/click/see_more_content';
import { seeMoreSegment } from './events/click/see_more_segment';
import { share } from './events/click/share';
import { search } from './events/click/search';
import { searchV2 } from './events/click/searchV2';
import { lumnizCoin } from './events/click/lumniz_coin';
import { lumnizLanding } from './events/click/lumniz_landing';
import { featuredContent } from './events/click/featured_content';
import { incitationAccount } from './events/click/incitation_account';
import { popinAdGamification } from './events/click/popin_ad_gamification';
import { incitationNewsletter } from './events/click/incitation_newsletter';
import { playlistPopin } from './events/click/playlist_popin';
import { playlistCard } from './events/click/playlist_card';
import { cardListPlaylistClick } from './events/click/card_list_playlist_click';

const CLICK_EVENT = 'Tracking:click';

class PianoClick {
  /**
   * @param {PianoEventManager} eventManager
   */
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.events = {
      game,
      chapter,
      highlight,
      newsletter,
      pdf,
      quiz,
      popin_account: popinAccount,
      popin_incentive: popinIncentive,
      popin_back_to_school: popinBackToSchool,
      bubble_incentive: bubbleIncentive,
      user_menu: userMenu,
      see_more_content: seeMoreContent,
      see_more_segment: seeMoreSegment,
      share,
      search,
      searchV2,
      lumnizCoin,
      lumnizLanding,
      featuredContent,
      incitation_compte: incitationAccount,
      popin_ad_gamification: popinAdGamification,
      incitation_newsletter: incitationNewsletter,
      playlist_popin: playlistPopin,
      playlist_card: playlistCard,
      card_list_playlist_click: cardListPlaylistClick,
      incentive_account: incentiveAccount,
    };
  }

  init() {
    document.addEventListener(CLICK_EVENT, this._onClick.bind(this), false);
  }

  /**
   * @param {string} type
   * @param {object} data
   * @private
   */
  _getData(type, data) {
    return type in this.events ? this.events[type](data) : null;
  }

  /**
   * @param {object} detail
   * @private
   */
  _onClick({ detail }) {
    this.eventManager.send('click', this._getData(detail.type, detail.data));
  }
}

export default PianoClick;
