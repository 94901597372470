/**
 * @param {object} data
 * @return {{social_media: string}|{social_media: string, feature: string}}}
 */
export const share = (data) => {
  let hitData = {
    social_media: data.social,
  };

  if (data.zone === 'popin') {
    hitData = {
      ...hitData,
      feature: 'partager',
    };
  }

  return hitData;
};
