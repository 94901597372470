const ZONES = {
  voir_plus_chevron: 'chevron',
  voir_plus_slider: 'slider',
  voir_plus_tous_les_contenus: 'tous_les_contenus',
};

/**
 * @param {object} data
 * @return {{zone: string, click: string}}
 */
export const seeMoreSegment = (data) => {
  const zone = ZONES[data.zone] || 'chapitre';

  return {
    click: 'voir_plus',
    zone,
  };
};
