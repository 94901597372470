import { splitUrls } from './utils/splitUrls';
import { getCookies } from './utils/cookies';

class DataLayer {
  constructor() {
    this.establishmentList = ['primaire', 'college', 'lycee'];
    this.containerTypesPMSValues = ['serie', 'program', 'brand']; // All of them are called 'Serie' functionnaly speaking
    this.contentTypesValues = ['article', 'video', 'game', 'quiz'];
    this.excludeThematics = ['bac', 'brevet', 'les-quiz'];
    this.Thematic = '';
    this.examens = ['liste_quiz_examen', 'liste_quiz_examen_matiere', 'hub_examen'];
    this.listQuizzes = ['liste_quiz_niveau', 'liste_quiz_matiere'];
    this.urlInfo = {};
    this.utag_data = {};
  }

  init() {
    this.urlInfo = splitUrls(window.location);
    this.cookies = getCookies();
    this.Thematic = window.thematic || this.cookies.thematic;

    this.setVars();

    DataLayer.listenOpenDidomiPopin();
    this.listenToHistoryTab();
  }

  setVars() {
    this.utag_data.offre = DataLayer.setOffre();
    this.utag_data.type_page = this.setTypePage();

    if (!this.urlInfo.isHomepage
      && !this.urlInfo.isSearchPage
      && !this.urlInfo.isGlobalArchivePage
    ) {
      this.utag_data.etablissement = this.setEtablissement();
    }

    if (
      !this.urlInfo.isHomepage
        && !this.urlInfo.isSearchPage
        && !this.urlInfo.isGlobalArchivePage
        && this.setLevel() !== undefined
    ) {
      this.utag_data.level = this.setLevel();
    }

    if (window.thematic) {
      this.utag_data.subject = this.setMatiere();
    }

    if (window.chapterSlug) {
      this.utag_data.chapitre = this.setChapitre();
    }

    if (this.urlInfo.argumentsUrl[0]) this.utag_data.URL_1 = `/${decodeURIComponent(this.urlInfo.argumentsUrl[0])}`;
    if (this.urlInfo.argumentsUrl[1]) this.utag_data.URL_2 = `/${decodeURIComponent(this.urlInfo.argumentsUrl[1])}`;
    if (this.urlInfo.argumentsUrl[2]) this.utag_data.URL_3 = `/${decodeURIComponent(this.urlInfo.argumentsUrl[2])}`;

    if (this.contentTypesValues.includes(this.urlInfo.contentType)) {
      this.utag_data.id_contenu = this.setIdContenu();
    }

    if (this.utag_data.type_page === 'video') {
      this.utag_data.nom_programme = DataLayer.setPromgramName();
    }

    if ((this.urlInfo.argumentsUrl[0] === 'recherche' || this.urlInfo.isSearchPage) && this.urlInfo.queryParam !== null) {
      this.utag_data.keyword_moteur_interne = this.urlInfo.queryParam;
    }

    this.utag_data.notice_shown = false;
    this.setIdLogin();
  }

  listenToHistoryTab() {
    document.addEventListener('history:data-view', (event) => {
      this.utag_data.chap1 = 'mon_profil';
      this.utag_data.chap2 = 'mon_historique';
      this.utag_data.nom_page = event.detail.category;
      this.utag_data.id_login = this.cookies.lumni_student_user_id;
    });
  }

  static listenOpenDidomiPopin() {
    document.addEventListener('layer:didomi-open', () => {
      window.utag_data.notice_shown = true;
    });
  }

  static getQuery() {
    return (new URLSearchParams(window.location.search)).get('query');
  }

  static isInternalSearchEngineResult() {
    const resultCount = document.querySelector('.results-count');

    return resultCount === null
      ? false
      : !resultCount.textContent.startsWith('Aucun');
  }

  static getCurrentPageNumber() {
    const resultCount = document.querySelector('.results-count');
    const pagerfanta = window.ENABLE_NEW_DA ? document.querySelector('.pagerfanta-v3') : document.querySelector('.pagerfanta-v2');

    if (pagerfanta === null) {
      return resultCount.textContent.startsWith('Aucun') ? '0' : '1';
    }

    const currentPage = document.querySelector('.pagination__item--current-page');

    return currentPage.textContent;
  }

  static getPageNumber() {
    const resultCount = document.querySelector('.results-count');

    if ((resultCount !== null && resultCount.textContent.startsWith('Aucun'))) {
      return '0';
    }

    return (new URLSearchParams(window.location.search)).get('page') || '1';
  }

  static getNbPages() {
    const pagerfanta = window.ENABLE_NEW_DA ? document.querySelector('.pagerfanta-v3') : document.querySelector('.pagerfanta-v2');
    return pagerfanta === null ? '0' : pagerfanta.dataset.total;
  }

  static setOffre() {
    return window.currentUniverse === 'student' ? 'LUMNIELEVE' : '';
  }

  setTypePage() {
    let val = '';

    if (this.urlInfo.isHomepage) val = 'portail';
    if (this.urlInfo.isSearchPage) val = 'recherche_etablissement';
    if (this.urlInfo.argumentsUrl[0] === 'recherche') val = 'recherche_globale';
    if (this.urlInfo.argumentsUrl.length === 1 && this.urlInfo.establishment && !this.urlInfo.schoolLevel) val = 'etablissement';

    if (this.urlInfo.argumentsUrl.length === 2 && this.establishmentList.includes(this.urlInfo.argumentsUrl[0])) val = 'niveau';
    if (this.urlInfo.argumentsUrl.length === 3 && this.establishmentList.includes(this.urlInfo.argumentsUrl[0])) val = 'matiere';
    if (this.urlInfo.argumentsUrl.length === 4 && this.establishmentList.includes(this.urlInfo.argumentsUrl[0])) val = 'chapitre';

    if (window.itemType === 'folder') val = 'dossier';
    if (this.containerTypesPMSValues.includes(window.itemType)) val = 'serie';
    if (this.urlInfo.isContentPage
      && this.urlInfo.contentType) val = DataLayer.formatSlug(this.urlInfo.contentType);
    if (this.urlInfo.argumentsUrl.includes('segment')) val = 'segment';
    if (this.urlInfo.isArchivePage) val = 'archives';
    if (this.urlInfo.argumentsUrl.includes('mes-contenus')) val = 'liste';
    if (this.urlInfo.argumentsUrl.includes('bac') || this.urlInfo.argumentsUrl.includes('brevet')) val = 'hub_examen';

    if (this.urlInfo.argumentsUrl.includes('les-quiz')) {
      if (val === 'hub_examen') {
        val = (this.urlInfo.thematic === 'bac' || this.urlInfo.thematic === 'brevet') ? 'liste_quiz_examen' : 'liste_quiz_examen_matiere';
      } else {
        val = (this.urlInfo.thematic === 'les-quiz') ? 'liste_quiz_niveau' : 'liste_quiz_matiere';
      }
    }

    this.setChapter();

    return val;
  }

  setEtablissement() {
    let val = '';

    if (this.cookies.establishment) {
      val = this.cookies.establishment;
    } else if (window.establishment && typeof window.establishment === 'string') {
      val = window.establishment;
    }

    return DataLayer.formatSlug(val);
  }

  setLevel() {
    return DataLayer.formatSlug(window.schoolLevel || this.cookies.schoolLevel);
  }

  setMatiere() {
    if (this.Thematic && !(this.excludeThematics.includes(this.urlInfo.thematic))) {
      return DataLayer.formatSlug(this.Thematic);
    }

    return '';
  }

  setChapitre() {
    let val = '';

    if (this.cookies
      && this.cookies.containerType === 'chapitre') {
      val = this.cookies.containerSlug;
    } else if (window.chapterSlug) {
      val = window.chapterSlug;
    }

    return DataLayer.formatSlug(val);
  }

  setChapter() {
    if (this.urlInfo.argumentsUrl.includes('connexion')
      || this.urlInfo.argumentsUrl.includes('mot-de-passe-oublie')
      || this.urlInfo.argumentsUrl.includes('reinitialiser-le-mot-de-passe')
    ) {
      this.utag_data.chap1 = 'connexion';
    }

    if (this.urlInfo.argumentsUrl.includes('inscription')) {
      this.utag_data.chap1 = 'inscription';
    }

    if (this.urlInfo.argumentsUrl.includes('mes-contenus')) {
      this.utag_data.chap1 = 'favoris';
      this.utag_data.chap2 = window.isUserAuthenticated ? 'logged' : 'freemium';
    }

    if (this.urlInfo.argumentsUrl.includes('mes-informations')) {
      this.utag_data.chap1 = 'mon_profil';
    }

    if (this.urlInfo.argumentsUrl.includes('mon-historique')) {
      this.utag_data.chap1 = 'mon_profil';
      this.utag_data.chap2 = 'mon_historique';
    }
  }

  setIdContenu() {
    const length = this.urlInfo.argumentsUrl.length - 1;
    const val = this.urlInfo.argumentsUrl[length];

    return DataLayer.formatSlug(val);
  }

  setNomPage() {
    let val = '';

    const typePageCheck = ['portail', 'etablissement', 'niveau', 'matiere', 'chapitre'];

    if (this.urlInfo.isSearchPage) {
      val = this.urlInfo.queryParam === null ? 'recherche' : 'resultats';
    }

    if (this.containerTypesPMSValues.includes(window.itemType)
      || this.contentTypesValues.includes(window.itemType)
      || window.itemType === 'folder'
      || this.cookies.containerType === 'folder') {
      const { length } = this.urlInfo.argumentsUrl;
      val = this.urlInfo.argumentsUrl[length - 1];
    }

    if (typePageCheck.includes(this.utag_data.type_page)) val = 'accueil';

    if (this.urlInfo.argumentsUrl.includes('segment')) val = window.rubricTitle;
    if (this.urlInfo.isArchivePage) val = 'archives';

    if (this.examens.includes(this.utag_data.type_page)) {
      val = (this.urlInfo.argumentsUrl.includes('bac')) ? 'bac' : 'brevet';
    }

    if (this.listQuizzes.includes(this.utag_data.type_page)) val = 'list-quiz';

    if (this.urlInfo.argumentsUrl.includes('inscription')) val = 'inscription';
    if (this.urlInfo.argumentsUrl.includes('connexion')) val = 'connexion';
    if (this.urlInfo.argumentsUrl.includes('mot-de-passe-oublie')) val = 'mot_de_passe_oublie';
    if (this.urlInfo.argumentsUrl.includes('reinitialiser-le-mot-de-passe')) val = 'mot_de_passe_reinitialisation';
    if (this.urlInfo.argumentsUrl.includes('mes-contenus')
      || this.urlInfo.argumentsUrl.includes('mon-historique')) val = 'Reprendre';
    if (this.urlInfo.argumentsUrl.includes('mes-informations')) val = 'donees_perso';

    return DataLayer.formatSlug(val);
  }

  getDataLayer() {
    return this.utag_data;
  }

  setIdLogin() {
    if (this.urlInfo.argumentsUrl.includes('mes-contenus')
    || this.urlInfo.argumentsUrl.includes('mes-informations')) {
      this.utag_data.id_login = this.cookies.lumni_student_user_id;
    }
  }

  static setPromgramName() {
    const element = document.getElementById('programTitle');

    return DataLayer.formatSlug(element.dataset.program);
  }

  static formatSlug(string) {
    return (string && typeof string === 'string') ? string.replace(/-| /g, '_').toLowerCase() : string;
  }
}

export default DataLayer;
