import { chapter } from './events/view/chapter';
import { myHistoric } from './events/view/my_historic';
import { myInfos } from './events/view/my_infos';
import { quiz } from './events/view/quiz';
import { search } from './events/view/search';
import { searchV2 } from './events/view/searchV2';

const VIRTUAL_PAGE_EVENT = 'Tracking:view';

class PianoVirtualPage {
  /**
   * @param {PianoEventManager} eventManager
   */
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.events = {
      chapter,
      my_historic: myHistoric,
      my_infos: myInfos,
      quiz,
      search,
      searchV2,
    };
  }

  init() {
    document.addEventListener(
      VIRTUAL_PAGE_EVENT,
      this._onPageView.bind(this),
      false,
    );
  }

  /**
   * @param {string} type
   * @param {object} data
   * @private
   */
  _getData(type, data = {}) {
    if (type === 'search') {
      type = 'searchV2';
    }
    return type in this.events ? this.events[type](data) : null;
  }

  /**
   * @param {object} detail
   * @private
   */
  _onPageView({ detail }) {
    const { route } = window.LUMNI_CONTEXT;
    this.eventManager.send('page', this._getData(route, detail?.data));
  }
}

export default PianoVirtualPage;
