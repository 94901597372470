import { getChapter } from '../../../../../utils/chapter';
import { getCookies } from '../../../../../utils/cookies';

const CHAPTER_ROUTES = ['chapter'];

const CHAPTER_TABS = {
  revise: 'pour_reviser',
  test_your_knowledge: 'pour_tester_tes_connaissances',
  learn_while_having_fun: 'pour_apprendre_en_t_amusant',
};

const TABS_BY_ROUTE = {
  my_contents: 'favoris',
  my_historic: 'reprendre',
  my_infos: 'mes_succes',
};

/**
 * @param {string} route
 * @return {string}
 * @private
 */
const _getActiveTab = (route) => {
  // Active tab for chapter at loading time can be different for establishments
  // So, we get the active one if it exists
  const activeTab = document.querySelector('.chapter-tabs__tab-buttons [data-active="true"]');

  return activeTab
    ? CHAPTER_TABS[activeTab.dataset.tab]
    : TABS_BY_ROUTE[route] || '';
};

/**
 * @param {object} context
 * @return {string}
 * @private
 */
const _getChapter = (context) => {
  if (CHAPTER_ROUTES.includes(context.route)) {
    return context.item.slug;
  }

  const { schoolLevel, thematic } = getCookies();
  const chapter = getChapter(schoolLevel, thematic);

  return chapter.slug || '';
};

export const qualification = () => {
  const context = window.LUMNI_CONTEXT;
  const { route } = context;

  return {
    chapter: _getChapter(context),
    tab: _getActiveTab(route),
  };
};
