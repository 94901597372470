const CAUSE = {
  video: 'video',
  replay: 'article',
  newsletter: 'newsletter',
  game: 'jeu',
  account: 'compte',
  quiz: 'quiz',
  play: 'video_reprise_lecture',
  article: 'article',
};

/**
 * @param {object} data
 * @return {{feature: string, cause: string}}
 */
export const popinGamificationBadge = (data) => ({
  feature: `badge_gamification_gagne_${data.title}_${data.level}`,
  cause: CAUSE[data.type],
});
